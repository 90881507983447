


















































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import BackButton from "@/components/BackButton.vue";
import { AppData } from "@/types/state";
@Component({
  components: { BackButton },
})
export default class TermsAndConditions extends Vue {
  @Getter("appData") appData!: AppData;
}
